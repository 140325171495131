import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var CanvasJS: any;
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  formCount: any={
    dayCount : 0,
    weekCount : 0,
    monthCount : 0,
    yearCount : 0
  };
  graphData: any;
  arrGraph: any[];
  dayCount: any;
  countrystateList: any;
  fromDate: any = ''
  toDate: any = ''
  calender: any = { todate: '', formdate: '' }
  minAge: Date;
  currentdate=new Date().getTime()
  selectedcountry: string;
  weekCount: any;
  monthCount: any;
  yearCount: any;
  xvalue: any;
  finalGraphData: any[];
  // reportsCountData: any;
  month: any;
  year: any;
  reportsCountData: { dayCount: number; weekCount: number; monthCount: number; yearCount: number; };
  monthArr: any=[];
  currentYear: any;
  currentMonth: any;
  constructor(   private fb: FormBuilder, private router: Router,private spinner:NgxSpinnerService, public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    // this.formsCountApi()
    this.getGraphData('default')
    this. chart()
    this.getcountry()
    // var today = new Date();
    // var minAge = 0;
    // this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    // let date = new Date()
    // this.fromDate = (date.getFullYear() - 1) +'-'+( date.getMonth() > 10 ? date.getMonth() : '0'+ (date.getMonth() + 1) )+ '-' + date.getDate()
    // this.toDate = date.getFullYear() +'-'+( date.getMonth() > 10 ? date.getMonth() + 1 : '0'+ (date.getMonth()+1) )+ '-' + date.getDate();
    // let stringDate = date.toString()
    // this.month = stringDate.split(' ')[1]
    // this.year = stringDate.split(' ')[3]
    this.formsCount();

 
  }

  formdate() {

    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
   
}
todate() {
    this.toDate = new Date(this.calender.todate)
    this.toDate = this.toDate.getTime()
}

  getcountry(){
    this.service.getCountryStates()
    .subscribe((data)=> {
      this.countrystateList=data
      console.log("countries list::", this.countrystateList)
    })
   
  }
  
  formsCount(){
    let date = new Date;
    this.monthArr = ['January','February','March','April','May','June','July','August','September','October','November','December']
     this.currentMonth=this.monthArr[date.getMonth()]
     this.currentYear = date.getFullYear();
    this.spinner.show()
    this.service.getApi('course/get-form-count',1).subscribe((res:any) => { 
      this.spinner.hide()
      if (res['status'] == 200) {
      this.formCount= res.body.data
         
        } 
        },error=>{
         this.spinner.hide()
        this.service.showErrorMessage('Internal server error')
        })
   
  }

  filterFormsCount(){
    this.spinner.show()
    this.service.getApi('course/get-form-count?country='+this.selectedcountry,1).subscribe((res:any) => { 
      console.log(res)
      if (res.body['status'] == 200) {
      this.formCount= res.body.data
        this.dayCount = res.body.data.dayCount
         this.spinner.hide()
        } 
      else if (res.body['status'] == 201){
        this.service.showErrorMessage('Count of forms not found')
        this.spinner.hide()
      }
        else {
         this.spinner.hide()
        this.service.showErrorMessage(res.body['message'])
        }
        },error=>{
         this.spinner.hide()
        this.service.showErrorMessage('Internal server error')
        })
   
  }
 
  resetCount(){
    this.formsCount()
  }
  getGraphData(val){
    if(val=="default"){
      var url='course/get-graph-data-for-application-status?fromDate='+this.currentdate + '&toDate='+this.currentdate
    }
    else{
      var url='course/get-graph-data-for-application-status?fromDate='+this.fromDate + '&toDate='+this.toDate
    }
    this.spinner.show()
    this.service.getApi(url,1).subscribe((res:any) => { 
      if (res['status'] == 200) {
       

        this.graphData= res.body.data
     console.log("Graph data", this.graphData)
     console.log(Object.values(this.graphData));
     this.arrGraph=[] 
     this.finalGraphData=[]
    console.log("ARRGRAPH", this.arrGraph)   
         this.spinner.hide()   
         this.graphData.forEach((element,i) => {
          this.arrGraph.push({
            key: (Object.keys(element)[0]).split('_')[0].toLowerCase(),
            label: Object.values(element)[0]
          })
        });  
        
        // this.xvalue=this.arrGraph[0].key
        // console.log(this.xvalue)
        for(var it in this.arrGraph){
          console.log(this.arrGraph[it])
 
          this.finalGraphData.push({
            label: this.arrGraph[it].key,
            y: this.arrGraph[it].label
          })
        }
        //console.log("ArrayGraph Data",this.arrGraph)
        console.log("Final graph data", this.finalGraphData)
        this.chart()
        } else {
         this.spinner.hide()
        this.service.showErrorMessage(res['status'])
        }
        },error=>{
         this.spinner.hide()
        this.service.showErrorMessage('Internal server error')
        })
   
  }

  reset(){
    this.getGraphData('default')

  }

  chart(){
    var chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      theme: "light2", // "light1", "light2", "dark1", "dark2"
      title:{
        text: "Progress Chart"
      },
      axisY: {
        title: "Total Applications"
      },
      data: [{        
        type: "column",  
        showInLegend: true, 
        legendMarkerColor: "grey",
        legendText: "Applications",
        // dataPoints: [
        //   {x:10 , y:20}
        // ]
        dataPoints: this.finalGraphData
      }]
    });
    chart.render();
  }
}
