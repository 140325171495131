import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $ : any;
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  
  forgetPasswordForm: FormGroup
  constructor(private service:ServiceService, private router:Router, private spinner:NgxSpinnerService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.forgetPasswordForm = new FormGroup({
      "email": new FormControl("", Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)]))
    })
  }

  forgotPassword() {
    this.spinner.show()
    let url = 'http://localhost:4200/reset-password'
    let object = {
      "webUrl": url,
      "email": this.forgetPasswordForm.value.email
    }
    console.log('object', object)
    this.service.getApi(`account/forget-password?email=${this.forgetPasswordForm.value.email}&webUrl=${url}`, 0).subscribe((res:any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#verifyEmail').modal('show');
        this.forgetPasswordForm.reset()
      } else {
        this.spinner.hide()
        this.service.showErrorMessage(res.status)
      }
    }, error => {
      this.spinner.hide()
      this.service.showErrorMessage(error.status)
    })
  }
}

