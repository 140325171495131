import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-subscription-history',
  templateUrl: './subscription-history.component.html',
  styleUrls: ['./subscription-history.component.css']
})
export class SubscriptionHistoryComponent implements OnInit {
  subscriptionData: any;
  subscriptionArrayHistory: any=[];
  accountDetails: any;
  accountData: any;
  subscriptionList: any=[];

  constructor(private fb: FormBuilder, private router: Router,private spinner:NgxSpinnerService, private service: ServiceService) { }

  ngOnInit() {
    window.scroll(0,0)
    this.accountData = JSON.parse(localStorage.getItem('ProfileDetails'))
    this.accountData.imageUrl = this.accountData.imageUrl ? this.accountData.imageUrl : 'assets/images/pick-1.png';
    this.getSubscriptionHistoryList()
}
getSubscriptionHistoryList(){
    this.service.showSpinner()
    this.service.getApi(`university/get-subscription-list-from-cart?page=0&pageSize=10&universityId=${this.accountData.universityDetailsId}&paymentStatus=PAID`,1).subscribe((res:any) => {
        console.log('res---->',res)
        if(res.body.status == 200){
          this.subscriptionList = res.body.data.resultList.content        }
        this.service.hideSpinner()
    })
}
}
