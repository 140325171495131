import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  type: any = 'password';
  show: boolean = false;
  accountDetails: any;

  constructor(private fb: FormBuilder, private router: Router, private spinner: NgxSpinnerService, private service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.accountDetails=JSON.parse(localStorage.getItem('ProfileDetails'))
    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required, Validators.minLength(8),Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }

  changePasswordFunction() {
    let object = {
      'newPassword': this.changePasswordForm.value.newPassword,
      'oldPassword': this.changePasswordForm.value.oldPassword

    }
    this.spinner.show()
    this.service.postApi('account/change-password', object, 1).subscribe((res: any) => {
      console.log("Response----->>", res)
      if (res['body']['status'] == 200) {
        this.service.showSuccessMessage("Password changed successfully!")
        this.spinner.hide()
      }
      else if (res['body']['status'] == 205) {
        this.service.showErrorMessage("Old Password doesn't match")
        this.spinner.hide()
      }
      else {
        this.spinner.hide()
        this.service.showErrorMessage(res['status'])
      }
    }, error => {
      this.spinner.hide()
      this.service.showErrorMessage('Internal server error')
    })

  }

  

}
