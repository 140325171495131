import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';



import { MyProfileComponent } from './component/profile/my-profile/my-profile.component';
import { MySubscriptionComponent } from './component/profile/my-subscription/my-subscription.component';
import { ChangePasswordComponent } from './component/profile/change-password/change-password.component';
import { ManageUsersComponent } from './component/profile/manage-users/manage-users.component';
import { CreateUsersComponent } from './component/profile/create-users/create-users.component';
import { SubscriptionHistoryComponent } from './component/profile/subscription-history/subscription-history.component';


import { UniversityDashboardComponent } from './component/dashboard/university-dashboard/university-dashboard.component';

import { ViewformNewapplicationComponent } from './component/viewform/viewform-newapplication/viewform-newapplication.component';
import { ReportComponent } from './component/viewform/report/report.component';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './component/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {NgxPaginationModule} from 'ngx-pagination';
import { NotificationComponent } from './component/notification/notification.component';
import { SubscriptionDetailsComponent } from './component/profile/subscription-details/subscription-details.component';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FindUniversityComponent } from './component/find-university/find-university.component';
import { FindCourseComponent } from './component/find-course/find-course.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { PymentComponent } from './component/pyment/pyment.component';


@NgModule({
  declarations: [
    AppComponent,
    
    MyProfileComponent,
    MySubscriptionComponent,
    ChangePasswordComponent,
    ManageUsersComponent,
    CreateUsersComponent,
    SubscriptionHistoryComponent,
    UniversityDashboardComponent,
    
    ViewformNewapplicationComponent,
    ReportComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
 
    NotificationComponent,
    SubscriptionDetailsComponent,
    FindUniversityComponent,
    FindCourseComponent,
    ContactUsComponent,
    PymentComponent,
  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    NgxPaginationModule,
    NgxIntlTelInputModule,
    BsDropdownModule.forRoot(),
  
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
