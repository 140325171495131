import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportToCsv } from 'export-to-csv';
declare var kendo: any;
declare var $:any;


@Component({
  selector: 'app-viewform-newapplication',
  templateUrl: './viewform-newapplication.component.html',
  styleUrls: ['./viewform-newapplication.component.css']
})

export class ViewformNewapplicationComponent implements OnInit {
   
  formId: any;
  formsData: any;
  pdfFile: any;
  sendApplicationForm: FormGroup;
  mbaData: any;
  fullTimeMba: any;
  executiveMba: any;
  academicQualification: any;
  scholarships: any;
  config: any;
  statusData: any;
  accountDetails: any;
  selectedStatus: any;
  selectedStatusData: any;
  offerArr: any=[];
  statusArr: any=[];
  dataObj: any={};
  allDataObj: any={};
  selectedUniversityObj: any={};
  selectedIndex: any;
  selectedActionObj: any={};
  questionsArr: any=[];
  dynamicDocArr:any=[{'type':'text','mandatory':'true','value':''}]
  selectedErrorIndex: any;
  appLogArr:any=[]
  actionList: any=[]
 
  constructor(private fb: FormBuilder, 
    private router: Router,
    private spinner:NgxSpinnerService, 
    private service: ServiceService, 
    private route:ActivatedRoute,
    ) { }

  ngOnInit() {

    window.scrollTo(0, 0);
    this.accountDetails = JSON.parse(localStorage.getItem('ProfileDetails'))
    let obj = this.route.params.subscribe(params => {
      this.formId = (params['id']); // (+) converts string 'id' to a number
   
       //localStorage.setItem('custId',this.formId)
       });
       this.formValidations()
       this.formDetails()
       this.getActions()
  }

  getActions() {
    let url = 'application/get-role-action-status?page=0&pageSize=999'
    this.service.getApi(url,1).subscribe((res:any) => {
      if(res.body.status == 200) {
       this.actionList = res.body.data.data.content
      }
    })

  }

  formValidations(){
    this.sendApplicationForm = new FormGroup({
      'status': new FormControl('', [Validators.required]),
      // 'reference': new FormControl('', [Validators.required]),
      'universityAction':new FormControl('',),
    });
  }

  
  formDetails(){
    this.spinner.show()
    this.service.getApi(`application/search-and-filters-forms?universityId=${this.accountDetails.userId}&role=UNIVERSITY&formId=${this.formId}`,1).subscribe((res:any) => { 
      this.spinner.hide()
      if (res['status'] == 200) {
        console.log(res)
        this.allDataObj = res.body.data.list[0]
        this.dataObj = JSON.parse(res.body.data.list[0].formData)
        // this.selectedUniversityObj = this.allDataObj.uniResponseDto.find(x=>x.universityEmail == (this.accountDetails.email || 'static_oxford@univ.global'))
        this.selectedUniversityObj = this.allDataObj.uniResponseDto.find(x=>x.universityEmail == this.accountDetails.email && x.statusList.length > 0)
        this.sendApplicationForm.patchValue({'status':this.selectedUniversityObj.statusList[0].applicationStatus})
        this.statusArr = this.selectedUniversityObj.statusList[0].universityAction.length != 0 ?  this.selectedUniversityObj.statusList[0].universityAction.split(',') : []

        console.log(this.dataObj)
        } 
      })
  }

  // download(status){
  //   if(status=='personalstatement'){
  //     var downloading = browser.downloads.download({
  //       url : this.formsData.urlStatement,
  //       filename : 'downloads',
  //       conflictAction : 'uniquify'
  //     });
  //   }
  // }
 
  currentStatus(){
    // this.spinner.show()
    // this.service.getApi('course/get-all-form-status?applicationStatus='+this.formsData.applicationStatus +'&role='+ 'UNIVERSITY',1).subscribe((res:any) => { 
    //   if (res['status'] == 200) {
    //     this.statusData=res.body.data
    //     this.offerArr = (res.body.data.optionForUniversity).split(',')
    //     this.statusArr = res.body.data.optionForUniversityCode.split(',')
    //     this.spinner.hide()
    //   }
    // })
  }
  
  ExportToCsv(){
    let data = [
      {
        FormId: this.formsData.formId,
        CandidateName : this.formsData.forname + this.formsData.sirName,
        CourseName : this.formsData.courseName,
        Duration :this.formsData.duration,
        EntryYear : this.formsData.dates,
        
        Title :this.formsData.title,
        SurName :this.formsData.sirName,
        ForeName :this.formsData.forname,
        PreferredName :this.formsData.preferredName,
        Gender :this.formsData.gender,
        EmailID :this.formsData.email,
        ConfirmedEmailID :this.formsData.email,
        CountryofPermanentResidence : this.formsData.permanentResidenceCountry,
        DateofBirth :this.formsData.dob,
        CountryofBirth :this.formsData.countryOfBirth,
        PassportNumber :this.formsData.passportNumber,
        RequiredvisatostudyintheUK :this.formsData.doUoyRequireVisatoStudtInTheUk,
        Ethnicity:this.formsData.ethenticity,
        Disability :this.formsData.disability,
        Description: this.formsData.description,
        RelevantCriminalConvictions :this.formsData.relevantCriminalConvictions,
        //ConvictionDescription :
        AppliedCourseName :this.formsData.courseName,
        StartYear :this.formsData.courseSttartDate,
        
        ContactCountry: this.formsData.country,
        ContactState: this.formsData.state,
        ContactAddress: this.formsData.address,
        ContactZipcode: this.formsData.zipcode,
        ContactTelephoneNumber: this.formsData.telephoneNo,
        ContactMobileNumber: this.formsData.phoneNo,
        ContactEmailAddress:this.formsData.contactEmail,
        
        HomeAddress: this.formsData.homeAddress,
        HomeTelephoneNo1: this.formsData.homeTeliphoneNo,
        HomeTelephoneNo2: this.formsData.homeTeliphoneNo2,
        HomeEmailAddress: this.formsData.homeEmail,
        
        AcademicQualifications: this.academicQualification.academicQualifications,
        AcademicQualificationsSubject: this.academicQualification.subject1,
        AcademicQualificationsResult: this.academicQualification.resultType,
        InstitutionName: this.academicQualification.instituteName,
        CountryOfState: this.academicQualification.countryOfStudy,
        StartDate: this.academicQualification.startDate,
        EndDate: this.academicQualification.enddate,
        HighestQualification: this.academicQualification.highestAcademicQualification,
        Achieved: this.academicQualification.achieved,
        
        QualificationTitle: this.formsData.qualificationTitle,
        Subject: this.formsData.professionalQualificationSubject,
        DateAwarded: this.formsData.awardedDate,
        ProfessionalBodyMembership: this.formsData.professionalBodyMembership,
        
        Referee1Name: this.formsData.referee1Name,
        Referee1Title: this.formsData.referee1Title,
        Referee1Address: this.formsData.referee1Address,
        Referee1TelephoneNumber: this.formsData.referee1TelephoneNumber,
        Referee1EmailAddress: this.formsData.referee1Email,
        Referee1Description: this.formsData.aboutReference1,
        
        Referee2Name: this.formsData.referee2Name,
        Referee2Title: this.formsData.referee2Title,
        Referee2Address: this.formsData.referee2Address,
        Referee2TelephoneNumber: this.formsData.referee2TelephoneNumber,
        Referee2EmailAddress: this.formsData.referee2Email,
        Referee2Description: this.formsData.aboutReference2,
        
      },
    ]
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Candidate Details CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(data);  
  }
  
  exportPDF(){
    kendo.drawing
    .drawDOM("#pdfcontent",
    {
      paperSize: "A5",
      margin: { top: "0.8cm", bottom: "1cm" },
      scale: 0.8,
      height: 500,
      
    })
    .then(function (group) {
      kendo.drawing.pdf.saveAs(group, "Exported.pdf")
    });
    
  }
  
  // ExportToPDF(){
    //   const PDFDocument = require('pdfkit');
    //   const fs = require('fs');
    //   const doc = new PDFDocument();
    
    //    doc.pipe(fs.createWriteStream('output.pdf'));
    
    //   doc.font('fonts/PalatinoBold.ttf')
    //    .fontSize(25)
    //    .text('Some text with an embedded font!', 100, 100);
    //    doc.end();
    // }
    
    // Upload pdf
    uploadPdf($event): void {
      var img = $event.target.files[0];
      console.log(img)
      this.uploadImageFunc(img);
    }
    uploadImageFunc(img) {
      var fb = new FormData();
      fb.append('file', img)
      this.service.showSpinner();
      this.service.postApiImg('account/upload-file', fb).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == '200') {
          this.service.showSuccessMessage("pdf uploaded successfully")
          this.pdfFile = res['data'];  
          //this.updateProfile();
        }
      }, err => {
        this.service.hideSpinner();
        if(err['status']=='401'){
          
          this.service.showErrorMessage('Unauthorized Access');
        }else{
          // this.service.toasterErr('Something Went Wrong');
        }
      })
    }
    
    sendApplicationStatus(){
      let data={
        "applicationStatus": this.sendApplicationForm.value.status,
        "applicationStatusCode": this.sendApplicationForm.value.statusCode,
        "courseId": this.formsData.courseId,
        "courseName": this.formsData.courseName,
        "descriptionForUniversity": "string",
        "descriptionForUser": this.formsData.personalStatementDescription,
        "formId": this.formsData.formId,
        "formUserEmail": this.formsData.email,
        "imageUrl": this.pdfFile,
        "representativeEmail": this.formsData.representativeEmail,
        "representativeId": this.formsData.representativeId,
        "representativeName": this.formsData.representativeName,
        "universityEmail": this.accountDetails.email,
        "universityId": this.accountDetails.universityDetailsId,
        "universityName": this.accountDetails.universityName
      }
      this.spinner.show()
      this.service.postApi('course/update-application-status',data,1).subscribe((res:any) => { 
        console.log(res)
        if (res.body['status'] == 200) {
          
          this.service.showSuccessMessage('Form details send successfully.')
          // this.formsData= res.body.data.formdata.content[0]
          //  console.log("FormsData=====>>", this.formsData)
          this.spinner.hide()     
        } else {
          this.spinner.hide()
          this.service.showErrorMessage(res.body['message'])
        }
      },error=>{
        this.spinner.hide()
        this.service.showErrorMessage('Internal server error')
      })
      
    }

    addItem() {
      this.dynamicDocArr.push({'type':'text','mandatory':'true','value':''})
    }
     
    removeItem(index) {
      this.dynamicDocArr.splice(index,1)
    }

    // to update form status
  updateStatus(val) {
    // this.selectedFormId = formId; // this.formId
       //this.selectedUniversityObj --> this.selectedObj = obj
    this.selectedIndex = this.statusArr.findIndex(x=>x.trim() == val.trim())
    this.selectedStatus = this.selectedUniversityObj.statusList[0].universityAppStatusCode.split(',')[this.selectedIndex]
    let action = val.trim()
    console.log(action,this.selectedStatus)
    // let code = this.selectedObj.statusList[0].repAppStatusCode.split(',')[this.selectedIndex]--> selectedStatus
    this.selectedActionObj = (this.actionList.find(x=>x.actionName.trim()==action.trim() && x.applicationStatusCode.trim()==this.selectedStatus.trim()))
    // this.selectedActionObj['detail'] = JSON.parse(this.selectedActionObj.details)
    console.log(this.selectedActionObj) 
    

    if(this.selectedActionObj.detailType == 'Survey' || this.selectedActionObj.detailType == 'Additional Documents-Static'){
      let questionsObj = this.selectedActionObj.details.split(',')[2]
    this.questionsArr = []
    questionsObj.split(':').forEach(ele => {
      this.questionsArr.push(
        {
          question: ele.split('|')[0].trim(),
          type: ele.split('|')[1].trim(),
          placement: ele.split('|')[2].trim(),
          mandatory: ele.split('|')[3].trim(),
          optionsArr: ele.split('|')[1].trim() == 'dropdown' ? ele.split('|')[2].trim().split('/') : []
        }
      )
    });
      $('#uploadSurvey').modal({backdrop:'static',keyboard:false})
    }else if(this.selectedActionObj.detailType == 'Additional Documents-Dynamic') {
      $('#uploadDynamicDocs').modal({backdrop:'static',keyboard:false})
    }else if(this.selectedActionObj.detailType == 'None') {
      $('#uploadNone').modal({backdrop:'static',keyboard:false})
    }
  }

  // on update api call
  onUpdateStatusCall() { 
    // let appLogArr = [];
    // if(this.selectedUniversityObj.applicationLog) {
    //   this.selectedUniversityObj.applicationLog.split('').forEach(ele => {
    //     appLogArr.push(ele)
    //   });
    // }
    
    let obj = {sender:'university',receiver:'user',applicationStatus:this.selectedActionObj.applicationStatusName,current_time:new Date().getTime()}
    // appLogArr.push(JSON.stringify(obj))
    let accountDetails = localStorage.getItem('ProfileDetails') ? JSON.parse(localStorage.getItem('ProfileDetails')) : {}
    let url = `application/form-status-update?formId=${this.formId}&role=UNIVERSITY&status=${this.selectedStatus}&universityId=${this.selectedUniversityObj.universityId}&userId=${accountDetails.userId}`
    let req= {
      'formId': this.formId,
      'role':'UNIVERSITY',
      'status': this.selectedStatus,
      'userId': this.accountDetails.userId,
      'universityId': this.selectedUniversityObj.universityId,
      "applicationLogDto": [
        {
          "applicationLog": JSON.stringify(obj),
          "formId": this.formId,
          // "fromRepresentative": "",
          // "fromStudent": "string",
          // "fromUniversity": "string",
          'universityId': this.selectedUniversityObj.universityId,
        }
      ],
    }
    //,university,question|text|placement:
    this.selectedActionObj.detailType = 'Additional Documents-Dynamic'
    if(this.selectedActionObj.detailType == 'Additional Documents-Dynamic') {
      let str = ''
      this.dynamicDocArr.forEach((ele,index) => {
        ele['value'] = document.getElementById(index+'doc')['value']
        ele['mandatory'] = document.getElementById(index+'mandate')['value']
        
        if(index == 0) {
          str =  `Please upload required documents,university,${ele['value']}|file||${ele['mandatory']}`
        }else {
          str =  str + `:${ele['value']}|file||${ele['mandatory']}`
        }
      });
      str = str+ `:Comments|textarea||false`
      if(document.getElementById('university-comments')['value']) {
        str =  str + `,${document.getElementById('university-comments')['value'].trim()}`
      }
      url = url + `&additionalDocuments=${JSON.stringify(str.trim())}`
    }
    
    console.log(url)
    
    this.service.showSpinner()
    this.service.putApi(url,req).subscribe((res:any) => {
      this.service.hideSpinner()
      if(res.body.status == 200){
        $('#uploadSurvey').modal('hide')
        $('#uploadDynamicDocs').modal('hide')
        $('#uploadNone').modal('hide')
        this.selectedStatus = null
        this.selectedIndex = null
        this.selectedErrorIndex = null
        this.formDetails()
      }
    })
  }

  onSurveySubmit() {
    this.selectedErrorIndex = null
    this.questionsArr.forEach((ele,index) => {
      console.log(index)
      ele['value'] = ele.value ? ele.value : document.getElementById(String(index))['value']
       if(ele.type == 'dropdown' && ele.value == 'yes') {
        ele['description'] = document.getElementById(index+'desc')['value']
      }
    });
    this.selectedErrorIndex = this.questionsArr.findIndex(x=>((x.value == '' && x.mandatory=='true') || (x.type=='dropdown' && x.value=='none') ))
    if(this.selectedErrorIndex != -1) {
      console.log(this.selectedErrorIndex)
      this.service.showErrorMessage('Form Invalid')
      return;
    }
    console.log(this.dataObj)
    let req = {
      fromEmail : this.accountDetails.email,
      toEmail: (this.dataObj.representativeEmail || this.dataObj.representativeemail || this.dataObj.studentEmail) ,
      message: JSON.stringify(this.questionsArr)
    }

    let url = 'application/send-mail'
    this.service.postApi(url,req,1).subscribe((res:any)=>{
      console.log(res)
      if(res.status == 200) {
        this.service.showSuccessMessage(res.body.message)
        this.onUpdateStatusCall()
      }else {
        this.service.showErrorMessage(res.body.message)
        this.close()
      }
    },(err)=>{
      this.service.showErrorMessage("Can't send Email!")
        this.close()
    })
  }

  close() {
        this.selectedStatus = null
        this.selectedIndex = null
        this.selectedErrorIndex = null
  }

  onDropChange(index,val) {
    this.questionsArr[index]['value'] = val
  }

  // to open activity log
  manageLog(log) {
    this.appLogArr = []
    console.log(log)
    if(log!=null && log.length >0) {
      // console.log(log)
      // this.appLogArr = JSON.parse(log)
      // '{"name":"shivam","job":"developer"}|{"name":"shivam1","job":"developer1"}'
     log.forEach(ele => {
        this.appLogArr.push(JSON.parse(ele.applicationLog))
      });
    }
    $('#appLog').modal('show')
  }



    
  }
  