import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppComponent } from 'src/app/app.component';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  token: any;
  remembers: boolean = false;
  type: any = 'password';
  show: boolean = false;
  universityName: any;
  constructor( private appC:AppComponent, private fb: FormBuilder, private router: Router, private spinner: NgxSpinnerService, private service: ServiceService) {

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      "email": ["", Validators.compose([Validators.required])],
      "password": ["", Validators.compose([Validators.required, Validators.maxLength(16)])],

      "remember": [true]
    })
    if (JSON.parse(localStorage.getItem('remembers')) == true) {
      this.loginForm.patchValue({
        "email": window.atob(localStorage.getItem('email')),
        "password": window.atob(localStorage.getItem('password')),
        "remember": true
      })
    } else {
      localStorage.clear();
      this.loginForm.reset();
    }


  }
  // ******************Login Api*******************//

  login() {
    this.spinner.show()
    if (localStorage != null) {
      localStorage.setItem('email', window.btoa(this.loginForm.value.email))
      localStorage.setItem('password', window.btoa(this.loginForm.value.password))
    } else {
      this.loginForm.reset();
    }
    localStorage.setItem('remembers', JSON.stringify(this.loginForm.value.remember))
    let object = {
      "email": this.loginForm.value.email,
      "password": this.loginForm.value.password,
      "portalType": "UNIVERSITY"
    }
    this.service.postApi('auth', object, 0).subscribe((res: any) => {
      this.spinner.hide();
      if (res['status'] == 200) {
        this.token = res.body.data.token
        localStorage.setItem('token', this.token);
        this.getProfileDetails() 
      }
      else if (res['body']['status'] == 401) {
        this.service.showErrorMessage(res.body.message)
         this.spinner.hide()
      }
      else if (res['body']['status'] == 203) {
        this.service.showErrorMessage('Please verify your email address')
        this.spinner.hide()
      }
      else {
        this.spinner.hide()
        this.service.showErrorMessage(res['status'])
      }
    }, error => {
      console.log(error)
      this.spinner.hide()
      this.service.showErrorMessage(error.error.message)
    })

  }

  getProfileDetails() {
    this.service.getApi('account/my-account',1).subscribe(res => {
      if(res['status']  == 200) {
        localStorage.setItem('ProfileDetails',JSON.stringify(res['body']['data']))
        this.service.showSuccessMessage('Login Successful!');
        this.router.navigate(['university-dashboard'])
      }
    });
   
  }

  

  toggleShow() {
    this.show = !this.show;
    if (this.show) {
      this.type = "text";
    }
    else {
      this.type = "password";
    }
  }

  openLink(link) {
    if (link == `home`)
      window.open(`http://ec2-35-176-66-190.eu-west-2.compute.amazonaws.com:1619/dashboard`)
    else if (link == `contact-us`)
      window.open(``)
  }

}
