import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $ :any;
@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.css']
})
export class SubscriptionDetailsComponent implements OnInit {
 
  accountData:any;
  subscriptionDetails:any;
  responseMessage:any;
  id:any

  constructor(private router: Router,private service:ServiceService,private activateRoute:ActivatedRoute) { }


  ngOnInit() {
    window.scroll(0,0)
    this.accountData = JSON.parse(localStorage.getItem('ProfileDetails'))
    this.accountData.imageUrl = this.accountData.imageUrl ? this.accountData.imageUrl : 'assets/images/pick-1.png';
    this.activateRoute.params.subscribe((res:any) => {
      this.id = res.id;
      this.getSubscriptionDetail(res.id)
    })
  }

  getSubscriptionDetail(id){
    this.service.showSpinner()
    this.service.getApi(`university/get-global-subscription-particular-data?id=${id}`,1).subscribe((res:any) => {
      if(res.body.status == 200){
        this.subscriptionDetails = res.body.data.data
        console.log('res-->',this.subscriptionDetails)
      }
      this.service.hideSpinner()
    })
  }

  addToCart(){
    this.service.showSpinner()
    // let globalSubscriptionDto = {
    //   "cost": this.subscriptionDetails.cost,
    //   "description": this.subscriptionDetails.description,
    //   "imageUrl": this.subscriptionDetails.imageUrl,
    //   "noOfUserLicences": this.subscriptionDetails.noOfUserLicences,
    //   "subscriptionModel": this.subscriptionDetails.subscriptionModel,
    //   "validity": this.subscriptionDetails.validity,
    // }
    this.service.postApi(`university/add-subscription-to-cart?globalSubscriptionId=${this.subscriptionDetails.globalSubscriptionId}&universityId=${this.accountData.universityDetailsId}`,{},1).subscribe((res:any) => {
      if(res.body.status == 200) {
        console.log('res--->',res)
      this.service.hideSpinner()
        // this.responseMessage = res.body.message;
      // $('#resetPassword').modal('show');
       this.service.showSuccessMessage(res.body.message)
       this.router.navigate(['pyment'],{queryParams  : {amount : this.subscriptionDetails.cost , id : res.body.data.addCartToSubscriptionId}})
       console.log('pyment')
       
      }
      
    })
  }
}
