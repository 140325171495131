import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-my-subscription',
  templateUrl: './my-subscription.component.html',
  styleUrls: ['./my-subscription.component.css']
})
export class MySubscriptionComponent implements OnInit {
  token: string;
  subscriptionData: any;
  subscriptionArrayHistory: any=[];
  accountDetails: any;

  constructor(private fb: FormBuilder, private router: Router,private spinner:NgxSpinnerService, private service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.accountDetails=JSON.parse(localStorage.getItem('ProfileDetails'))
    this.token = localStorage.getItem('token')
    this.mySubscription()
  }
  mySubscription(){
    this.spinner.show()
    this.service.getApi('university/get-subscription-list?page=0&pageSize=5',1).subscribe((res:any) => { 
     
      if (res['status'] == 200) {
      
       this.subscriptionData= res.body.data.resultList.content
       
       this.subscriptionData.forEach((element,index) => {
         this.subscriptionArrayHistory.push({
           "cost": this.subscriptionData[index].cost,
           "description": this.subscriptionData[index].description,
           "subscriptionModel": this.subscriptionData[index].subscriptionModel,
           "validity": this.subscriptionData[index].validity,
           "imageUrl": this.subscriptionData[index].imageUrl,
           "noOfUserLicences": this.subscriptionData[index].noOfUserLicences,
           "updateTime": this.subscriptionData[index].updateTime,
           "globalSubscriptionId": this.subscriptionData[index].globalSubscriptionId
         })
       });
       console.log(this.subscriptionArrayHistory)
        //this.service.showSuccessMessage("Login successfully")
       
        
        //this.router.navigate(['university-dashboard'])
         this.spinner.hide()
      
        
       
        } else {
         this.spinner.hide()
        this.service.showErrorMessage(res['status'])
        }
        },error=>{
         this.spinner.hide()
        this.service.showErrorMessage('Internal server error')
        })
   
  }

  subscriptionDetails(Univ_id){
    this.router.navigate(['subscription-details/'+ Univ_id])
  }

  

}
