import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $ :any
@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
 pageNumber: number=1;
  userListDetails: any;
  deleteId: any;
  accountDetails: any;
  constructor(private fb: FormBuilder, private router: Router,private spinner:NgxSpinnerService, private service: ServiceService, private route:ActivatedRoute) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.accountDetails=JSON.parse(localStorage.getItem('ProfileDetails'))
    this.userList()
  }

  userList(){
    this.spinner.show()
    this.service.getApi('account/filter-user-details?page='+(this.pageNumber-1) + '&pageSize='+'5' +'&roleStatus='+'UNIVERSITY_USER',1).subscribe((res:any) => { 
      if (res['status'] == 200) {
      console.log(res)
      this.userListDetails=res.body.data.list
      // this.formsData= res.body.data.formdata.content[0]
      //  console.log("FormsData=====>>", this.formsData)
         this.spinner.hide()     
        } else {
         this.spinner.hide()
        this.service.showErrorMessage(res['status'])
        }
        },error=>{
         this.spinner.hide()
        this.service.showErrorMessage('Internal server error')
        })
   
  }
  
  pagination(page) {
    this.pageNumber=page
  }

  delete(userId){
    $("#deletemodal").modal('show');
    this.deleteId=userId
   }

  confirmDelete(){
    this.spinner.show()
    this.service.getApi('account/delete-user-detail-otherRole?userId='+this.deleteId,1).subscribe((res:any) => { 
      if (res['status'] == 200) {
        $("#deletemodal").modal('hide')
     this.service.showSuccessMessage('User deleted successfully')
         this.spinner.hide()  
         this.userList()   
        } else {
          $("#deletemodal").modal('hide')
         this.spinner.hide()
        this.service.showErrorMessage(res['status'])
        }
        },error=>{
          $("#deletemodal").modal('hide')
         this.spinner.hide()
        this.service.showErrorMessage('Internal server error')
        })
  }
}
