import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router';
declare var $ : any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
 
  resetPasswordForm:FormGroup;
  responseMessage: any;
  token : any;
  constructor(private activateRoute:ActivatedRoute,private service:ServiceService, private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeform()
    this.activateRoute.queryParams.subscribe((query :any) => {
      if(query.token){
        console.log('token--->>',query.token)
        this.token = query.token
        // this.verifyEmail(query.token)
      }
    });
  }

  initializeform(){
    this.resetPasswordForm = new FormGroup({
      password : new FormControl('',[Validators.required,Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]),
      cnfmpassword: new FormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
    })
  }

  verifyEmail(token){
      this.spinner.show()
      this.service.getApi(`account/verify-user?token=${token}`,2).subscribe((res:any) => {
        console.log("verify mail res--->",res)
        this.spinner.hide()
        this.responseMessage = res.body.message
        $('#verifyEmail').modal('show')
      },error => {
        this.spinner.hide()
      })
  }

  changePassword(){
    let resetPasswordDto = {
      "password": this.resetPasswordForm.value.password,
      "token": this.token
    }
    this.spinner.show()
    this.service.postApi(`account/reset-password`,resetPasswordDto,1).subscribe((res:any) => {
      console.log('res-->>',res)
      if(res.body.status==200){
        this.spinner.hide()
        this.responseMessage = res.body.message
        $('#resetPassword').modal('show');
      }
     else{
      this.spinner.hide()
      this.responseMessage = res.body.message
       }    
    })
  }

}

