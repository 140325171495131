import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpEvent} from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Observable,BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  universityName: any = ''  
  month: any;
    day: any;
    daily: string;
    year: number;
    dtToday: Date;
    maxDate: string;
  code: string;
    constructor(private http:HttpClient,private spinner: NgxSpinnerService, private toastr: ToastrService) { }

    // baseUrl = "http://ec2-3-22-2-94.us-east-2.compute.amazonaws.com:2001/"
    baseUrl = "https://try.readme.io/http://apistag.univ.global:2001/";
    
    webUrl = "http://localhost:4200/"
    loginSub = new BehaviorSubject(``);
    loginObs = this.loginSub.asObservable();
    changeLoginSub(msg) {
      this.loginSub.next(msg);
    }
//================ POST API =========================//
    postApi(url, data, Header) {
        if (Header == 1) {
            var httpOptions;
            httpOptions = {
              headers: new HttpHeaders({
                "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
              }), observe: 'response'
            }
        } 
        else {
            var httpOptions;
            httpOptions = {
              headers: new HttpHeaders({
                "Content-Type": "application/json",
              }), observe: 'response'
            }
        }
        return this.http.post((this.baseUrl+ url), data, httpOptions)  
    }
  //============== POST API FOR IMAGES =============///

  postApiImg(endPoint, data) {
    if (localStorage.getItem('token')) {
      this.code = localStorage.getItem('token')
    }
    if (localStorage.getItem('token')) {
     
      var httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${this.code}` })
      }
    }
    return this.http.post(this.baseUrl + endPoint, data, httpOptions);
  }

//================ GET API =========================//    
    getApi(url, isHeader) {
        if (isHeader == 1) {
          var httpOptions;
          httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            }), observe: 'response'
          }
        }
        else {
          var httpOptions;
          httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }), observe: 'response'
          }
        }
        return this.http.get((this.baseUrl + url), httpOptions)
    }

//================ PUT API =========================//
    putApi(url,data:any){
        var httpOptions;
        let accountDetails = localStorage.getItem('ProfileDetails') ? JSON.parse(localStorage.getItem('ProfileDetails')) : {}
        httpOptions={
            headers: new HttpHeaders({
                "token": localStorage.getItem('token'),
                "userId": accountDetails.userId
            }),observe: 'response'
        }
        return this.http.put(this.baseUrl + url,data,httpOptions)
    }

//================ DELETE API =========================//
    deleteApi(url) {
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('token')
            }), observe: 'response'
        }
        return this.http.delete(this.baseUrl + url, httpOptions);
    }

//================ SHOW SPINNER =========================//
    showSpinner() {
        this.spinner.show();
    }
    
//================ HIDE SPINNER =========================//
    hideSpinner() {    
        this.spinner.hide();
    }

//================ SHOW Succes TOASTR =========================//
    showSuccessMessage(message){
        this.toastr.success(message)
    }

//================ HIDE Succes TOASTR =========================//
    showErrorMessage(message){
        this.toastr.error(message)
    }

    getCountryStates(): Observable<any> {
      return this.http.get("./assets/json/countrystateList.json");
    }
    BlockFuture() {
        $(() => {
          this.dtToday = new Date();
          this.month = this.dtToday.getMonth() + 1;
          this.day = this.dtToday.getDate();
          this.year = this.dtToday.getFullYear();
          if (this.month < 10)
            this.month = '0' + this.month.toString();
          if (this.day < 10)
            this.day = '0' + this.day.toString();
          this.maxDate = this.year + '-' + this.month + '-' + this.day;
          $('#fromDate').attr('max', this.maxDate);
          $('#toDate').attr('max', this.maxDate);
        });
      }
}

/*********************************** INTERCEPTOR *****************************************/

@Injectable()
export class HttpModifierInterceptor implements HttpInterceptor {
    constructor(private router: Router, private service: ServiceService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(res => {
            if (res instanceof HttpResponse) {
                if (res.status !== 200) {
                  this.service.hideSpinner()
                    setTimeout(x => {
                    }, 5000);
                }
            }
        }, err => {
            if (err instanceof HttpErrorResponse) {
                if (err.error.status === 401) {
                    localStorage.clear()
                    this.service.hideSpinner()
                    this.router.navigateByUrl('')
                    setTimeout(x => {
                    }, 5000);
                    this.router.navigateByUrl('login');
                } else {
                    localStorage.clear()
                    this.service.hideSpinner()
                    this.router.navigateByUrl('login');
                    setTimeout(x => {
                    }, 500);
                }
            }
        }));

    }
}
