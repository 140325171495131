import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { MyProfileComponent } from './component/profile/my-profile/my-profile.component';
import { ChangePasswordComponent } from './component/profile/change-password/change-password.component';
import { CreateUsersComponent } from './component/profile/create-users/create-users.component';
import { ManageUsersComponent } from './component/profile/manage-users/manage-users.component';
import { MySubscriptionComponent } from './component/profile/my-subscription/my-subscription.component';
import { SubscriptionHistoryComponent } from './component/profile/subscription-history/subscription-history.component';
import { UniversityDashboardComponent } from './component/dashboard/university-dashboard/university-dashboard.component';
import { ViewformNewapplicationComponent } from './component/viewform/viewform-newapplication/viewform-newapplication.component';
import { ReportComponent } from './component/viewform/report/report.component';
import { LoginComponent } from './component/login/login.component';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { AuthGuard } from './auth.guard';
import { NotificationComponent } from './component/notification/notification.component';
import { SubscriptionDetailsComponent } from './component/profile/subscription-details/subscription-details.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { FindCourseComponent } from './component/find-course/find-course.component';
import { FindUniversityComponent } from './component/find-university/find-university.component';
import { PymentComponent } from './component/pyment/pyment.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: "/login",
    pathMatch: "full"
  },
  { path : 'login', component: LoginComponent },
  { path: 'university-dashboard', component: UniversityDashboardComponent,canActivate :[AuthGuard]},
  { path: 'viewform-newapplication/:id', component: ViewformNewapplicationComponent,canActivate :[AuthGuard]},
  { path: 'report', component: ReportComponent,canActivate :[AuthGuard]},


  { path: 'my-profile', component : MyProfileComponent,canActivate :[AuthGuard]},
  { path: 'change-password', component : ChangePasswordComponent,canActivate :[AuthGuard]},
  { path: 'create-users', component : CreateUsersComponent,canActivate :[AuthGuard]},
  { path: 'manage-users', component : ManageUsersComponent,canActivate :[AuthGuard]},
  { path: 'my-subscription', component : MySubscriptionComponent,canActivate :[AuthGuard]},
  { path: 'subscription-history', component : SubscriptionHistoryComponent,canActivate :[AuthGuard]},
  { path: 'forget-password', component : ForgetPasswordComponent},
  { path: 'reset-password', component : ResetPasswordComponent},
  { path: 'notification', component : NotificationComponent,canActivate :[AuthGuard]},
  { path: 'subscription-details/:id', component: SubscriptionDetailsComponent,canActivate :[AuthGuard]},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'find-course', component: FindCourseComponent},
  { path: 'find-university', component: FindUniversityComponent},
 
  { path: 'pyment', component: PymentComponent ,canActivate :[AuthGuard]},
  
  
  

 
  // { path: 'section3',
  // { path: 'section3',
  // { path: 'section3',
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
