import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-university-dashboard',
  templateUrl: './university-dashboard.component.html',
  styleUrls: ['./university-dashboard.component.css']
})
export class UniversityDashboardComponent implements OnInit {
  formsData: any=[];
  formListArr: any=[];
  pageNumber: number=1;
  fromDate: any = ''
  toDate: any = ''
  calender: any = { todate: '', formdate: '' }
  minAge: Date;
  searchtext: string;
  universityName: any;
  accountDetails: any={};
  mainTab: any='DASHBOARD';
  subTab: any;
  selectedValue: any='COMPLETE';
  type: any;
  page: any=1;
  total: any=0;


  constructor(private fb: FormBuilder, private router: Router,private spinner:NgxSpinnerService, public service: ServiceService) { }

  ngOnInit() {
    this.accountDetails = JSON.parse(localStorage.getItem('ProfileDetails'))
    console.log( this.accountDetails.userId,JSON.parse(localStorage.getItem('ProfileDetails')))
      this.chooseTab("DASHBOARD",'','COMPLETE')
    window.scrollTo(0, 0);
    //this.service.BlockFuture()
    // this.defaultList(this.pageNumber)
    //this.filterForms()
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
  }

  // defaultList(pageNumber){
  //   this.spinner.show()
  //   this.service.getApi('course/get-forms-all-list?pagesize=' +'5'+ '&page='+(pageNumber-1) +'&universityId='+this.accountDetails.universityDetailsId,1).subscribe((res:any) => { 
  //     if (res['status'] == 200) {
      
  //      this.formsData= res.body.data
  //      this.total=res.body.data.length
  //     // console.log("FormsData=====>>", this.formsData)
  //     this.formListArr=[]
  //      this.formsData.forEach((obj,i) => {
  //        if(obj!= null) {
  //         this.formListArr.push({
  //           "formId": this.formsData[i]['formId'],
  //           "dates": this.formsData[i].creationTime,
  //           "email":  this.formsData[i].email,
  //           "applicationStatus": this.formsData[i].applicationStatus,
  //           "name": this.formsData[i].forname + this.formsData[i].sirName,
  //           "representativeName":this.formsData[i].employersName
  //         })
  //        }
        

        
  //      });
  //      //console.log("formarray--->>", this.formListArr)
  //     //  formListArr.forEach(element => {
         
  //     //  });
  //       //this.service.showSuccessMessage("Login successfully")
       
        
  //       this.router.navigate(['university-dashboard'])
  //        this.spinner.hide()
      
        
       
  //       } else {
  //        this.spinner.hide()
  //       this.service.showErrorMessage(res['status'])
  //       }
  //       },error=>{
  //        this.spinner.hide()
  //       this.service.showErrorMessage('Internal server error')
  //       })
   
  // }

  // defaultForms(){
  //   this.defaultList(this.pageNumber)
  // }

  chooseTab(main_tab,sub_tab,status) {
    this.mainTab = main_tab;
    this.subTab = sub_tab;
    if(status.length !=0) {
      this.filterForms(status)
    }

    // if(has_sub_tab == 'YES'){
    //   this.mainTab = tab_name
    // }else if(has_sub_tab == 'NO') {
    //   this.subTab = tab_name
    // }
    // if(type == 'MAIN_TAB') {
    //   if(has_sub_tab == 'YES') {
    //     this.mainTab = tab_name;
    //   } else {
    //     this.mainTab = tab_name;
    //     this.filterForms(tab_name)
    //   }  
    // }else {
    //   this.subTab = tab_name;
    // }
    // this.pageNumber = 1;
  }

  filterForms(val){
    this.formListArr = [];
    this.selectedValue = val
    // let url = '';
    // url = `course/filter-forms-details?pagesize=5&page=${this.pageNumber - 1}&formFillStatus=COMPLETE&university=${this.accountDetails.universityDetailsId}`;
    // if(val !='DEFAULT') {
    //   url = url + `&search=${val}`
    // }
    // if(this.fromDate && this.toDate){
    //   url = url + `&fromDate=${this.fromDate}&toDate=${this.toDate}`
    // }
    this.type=val
    // let url = `course/filter-forms-details?page=0&formFillStatus=COMPLETE&representativeId=${this.accountDetails.representativeDetailsId}`
    let url = `application/search-and-filters-forms?page=${this.page-1}&pageSize=5&universityId=${this.accountDetails.userId}&currentStatus=COMPLETE&role=UNIVERSITY`
    // let url = `application/search-and-filters-forms?status=${this.type}`
    if(this.type != 'COMPLETE') {
      url = url + `&status=${this.type}`
    }
    if(this.fromDate && this.toDate){
      url = url + `&fromDate=${this.convertIntoTimeStamp(this.fromDate)}&toDate=${this.convertIntoTimeStamp(this.toDate)}`
    }

    // if(this.filterBy && this.searchKey){
    //   if(this.filterBy == 'search_by_form_id') {
    //     url = url + `&formId=${this.searchKey}`
    //   }else if(this.filterBy == 'submitted_by') {
    //     url = url + `&submitedBy=${this.searchKey}`
    //   }else if(this.filterBy == 'candidateName') {
    //     url = url + `&candidateName=${this.searchKey}`
    //   }
    // }
    this.spinner.show()
    this.service.getApi(url,1).subscribe((res:any) => {
      // this.spinner.hide();
      // if(succ['body']['status'] == 200){
      //   this.formListArr = succ['body'].data.list
      //   this.formListArr.map(obj =>{
      //     obj['status'] = obj.formListStatucDto[0].applicationStatus
      //   })
      //   this.total = succ['body'].data.totalCount
      //   console.log(this.formListArr)
      // }

      this.spinner.hide()
      if(res.body.status == 205 || res.body.status == 200){
        this.formListArr = res.body.data.list
        this.formListArr.forEach(ele => {
          ele['parsedData']= JSON.parse(ele.formData)
        });
        this.total = res.body.data.totalCount
              //   this.total = succ['body'].data.totalCount

      }

    },err => {
      this.spinner.hide();
      console.log(err)
    })
   
    // if (val=="defaultsearch"){
      
    //   var url= 'course/filter-forms-details?pagesize=' +'5' + '&page='+(this.pageNumber-1) + '&fromDate='+ this.fromDate + '&toDate='+this.toDate + '&search='+this.searchtext + '&formFillStatus='+'COMPLETE';
    // }
    // else{
    //   var url= 'course/filter-forms-details?pagesize=' +'5' + '&page='+(this.pageNumber-1) + '&search='+val+ '&formFillStatus='+'COMPLETE';
    // }
    // this.spinner.show()
    // this.service.getApi(url,1).subscribe((res:any) => { 
    //   if (res['status'] == 200) {
    //     this.spinner.hide()

    //   //  this.formsData= res.body.data['allformList']['content']
    //   this.formsData= res.body.data['list']
    //   this.total= res.body.data.totalCount
    //   this.formListArr=[]
    //    this.formsData.forEach((element,i) => {
    //      this.formListArr.push({
    //        "formId": this.formsData[i].formId,
    //        "dates": this.formsData[i].creationTime,
    //        "email":  this.formsData[i].email,
    //        "applicationStatus": this.formsData[i].formListStatucDto[0].applicationStatus,
    //        "name": this.formsData[i].customerName,
          
    //      })

        
    //    });
    //    console.log("formarray--->>", this.formListArr)
    
       
        
    //   //   this.router.navigate(['university-dashboard'])
        
      
        
       
    //     } else {
    //      this.spinner.hide()
    //     this.service.showErrorMessage(res['status'])
    //     }
    //     },error=>{
    //      this.spinner.hide()
    //     this.service.showErrorMessage('Internal server error')
    //     })
   
  }

  convertIntoTimeStamp(myDate) {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[2] + "/" + myDate[0];
    console.log(new Date(newDate).getTime());
    return (new Date(newDate).getTime())
  }


  particularFormData(item){
    this.router.navigate(['viewform-newapplication/'+ item.formId])  
  }

  formdate() {

    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
   
}
todate() {
    this.toDate = new Date(this.calender.todate)
    this.toDate = this.toDate.getTime()
}
reset(){
  this.fromDate=''
  this.toDate=''
  this.searchtext=''
  this.pageNumber = 1;
  this.formListArr = [];
  this.mainTab = 'DEFAULT'
  this.filterForms('DEFAULT')
}
pagination(page) {
  console.log("page no:",page)
  this.pageNumber=page
  this.filterForms(this.selectedValue)
}


navigateTo(){
  this.router.navigateByUrl('/report');

}
}
