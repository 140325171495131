import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'univ';
  tokenAvailable: any;
  notificationCount: any;
  profileImg: any;


  constructor(private router: Router, private spinner: NgxSpinnerService, private service: ServiceService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.tokenAvailable = localStorage.getItem('token') ? localStorage.getItem('token') : '';
      }
    })

  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.myProfile()
    }

  }

  openSocialLink(social) {
    if (social == `youtube`)
      window.open(`https://youtube.com`)
    else if (social == `facebook`)
      window.open(`https://www.facebook.com/universitiesglobal`)
    else if (social == `twitter`)
      window.open(`https://twitter.com/UniversitiesG`)
    else if (social == `linkedin`)
      window.open(`https://www.linkedin.com/company/universitiesglobal/`)
    else if (social == `instagram`)
      window.open(`https://www.instagram.com/universitiesglobal/`)
  }

  navigateToMyProfile() {
    this.router.navigateByUrl('/my-profile')
  }

  Logout() {
    $("#logoutModal").modal('show');
  }

  confirmLogout() {
    this.spinner.show()
    localStorage.removeItem('token')
    $("#logoutModal").modal('hide')
    this.spinner.hide()
    this.router.navigateByUrl('/login')
  }

  notification() {
    this.router.navigateByUrl('/notification')
  }

  getNotification() {
    this.service.showSpinner()
    this.service.getApi(`course/get-notification-list?page=0&pageSize=100&universityId=${JSON.parse(localStorage.getItem('ProfileDetails')).universityDetailsId}`, 1).subscribe((res: any) => {
      if (res.body.status == 200) {
        this.notificationCount = res.body.data.countByCourseId
        console.log("Notification count: ", this.notificationCount)
      }
      this.service.hideSpinner()
    })
  }
  myProfile() {
    this.spinner.show()
    this.service.getApi('account/my-account', 1).subscribe((res: any) => {
      //console.log(res)
      if (res['status'] == 200) {
        this.profileImg = res.body.data.imageUrl
        this.service.universityName = res.body.data.universityName;
        localStorage.setItem('ProfileDetails', JSON.stringify(res.body.data));
        this.getNotification();

        this.spinner.hide()
      } else {
        this.spinner.hide()
        this.service.showErrorMessage(res['status'])
      }
    }, error => {
      this.spinner.hide()

    })

  }

}
