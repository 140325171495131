import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.css']
})
export class CreateUsersComponent implements OnInit {
  accountData:any;
  countryList:any = []
  stateList:any = []
  createUserForm:FormGroup;
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom,CountryISO.India];
  validHomeTelephoneNo: boolean = true;
  validmobileNumber : boolean = true;
  submitted : boolean = false;
  editImage: any;
  profiledata: any;
  
  constructor(private service:ServiceService,private router:Router,
    private spinner:NgxSpinnerService) { }

  ngOnInit() {
    window.scroll(0,0)
    this.accountData = JSON.parse(localStorage.getItem('ProfileDetails'))
    //this.accountData.imageUrl = this.accountData.imageUrl ? this.accountData.imageUrl : 'assets/images/pick-1.png';
    this.service.getCountryStates().subscribe((res: any) => {
      this.countryList = res
    })
    this.initializeForm()
    this.getMyProfile()
  }

  initializeForm(){
    this.createUserForm = new FormGroup({
      "firstName" : new FormControl('',[Validators.required,Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      "lastName" : new FormControl('',[Validators.required,Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      "telephoneNumber" : new FormControl('',[Validators.required]),
      "mobileNumber" : new FormControl('',[Validators.required]),
      "businessName" :  new FormControl('',[Validators.required,Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      "email" : new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      "address1" : new FormControl('',[Validators.required]),
      "address2" : new FormControl('',[Validators.required]),
      "address3" : new FormControl('',[Validators.required]),
      "zipCode" : new FormControl('',[Validators.required]),
      "city" : new FormControl('',[Validators.required]),
      "state" : new FormControl('',[Validators.required]),
      "country" : new FormControl('',[Validators.required]),
    })
  }

  homeTelephoneValidOrNot(){
    let key = this.createUserForm.controls['telephoneNumber'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validHomeTelephoneNo = key['validatePhoneNumber'].valid
      }else{
        this.validHomeTelephoneNo = true
      }
    }else{
      this.validHomeTelephoneNo = true
    }
  }

  contactValidOrNot(){
    let key = this.createUserForm.controls['mobileNumber'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validmobileNumber = key['validatePhoneNumber'].valid
      }else{
        this.validmobileNumber = true
      }
    }else{
      this.validmobileNumber = true
    }
  }

  getState(event){
    // this.section3Form.patchValue({
    //   state : ''
    // })
    var States = []
    States = this.countryList.filter((res) => res.country === event.target.value)
    this.stateList = States[0].states;
  }

  getMyProfile() {
    this.service.getApi('account/my-account', 1).subscribe((res:any) => {
      //console.log('profileeeee', res)
      if (res.status == 200) {
        this.profiledata = res.body.data
       
          //this.myProfileImage = this.profiledata.imageUrl ? this.profiledata.imageUrl : 'assets/images/pick-1.png';
          this.createUserForm.patchValue({
            'businessName': this.profiledata.universityName
          })
          this.getState(this.profiledata.country)
          
        }
                
    })
  }

  
  createUser(){
    this.submitted = true
    if(this.createUserForm.invalid){
      return false
    }
    this.spinner.show()
    let signupDto = {
      "address": "string",
      "address1": this.createUserForm.value.address1,
      "address2": this.createUserForm.value.address2,
      "address3": this.createUserForm.value.address3,
      "city": this.createUserForm.value.city,
      "country": this.createUserForm.value.country,
      "customerStatus": "UNSUBSCRIBE",
      "description": "string",
      "deviceToken": "string",
      "deviceType": "string",
      "dob": "string",
      "email": this.createUserForm.value.email,
      "firstName": this.createUserForm.value.firstName,
      "imageUrl": this.editImage,
      "lastName": this.createUserForm.value.lastName,
      "mobileNumber": this.createUserForm.value.mobileNumber.internationalNumber,
      "phoneNo": this.createUserForm.value.telephoneNumber.internationalNumber,
      "password": "univGlobal@321",
      "representativeDetailsId": 'string',
      "representativeName": 'string',
      "roleStatus": "UNIVERSITY_USER",
      "state": this.createUserForm.value.state,
      "subject": "string",
      "universityCode": this.accountData.universityCode,
      "universityDetailsId": this.accountData.universityDetailsId,
      "universityName": this.accountData.universityName,
      "userType": "STUDENT",
      "webUrl": "http://localhost:4200/reset-password",
      "zipcode": this.createUserForm.value.zipCode
    }
    console.log('--->>',this.createUserForm.value)
    this.service.postApi(`account/add-sub-user`,signupDto,1).subscribe((res:any) =>{
      this.spinner.hide()
      console.log('res--->',res)
      if(res.body.status == 200){
        this.router.navigateByUrl('/manage-users');
      }
    })
  }


  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
   // Image Functionality Start Here
uploadImg($event): void {
  var img = $event.target.files[0];
  
  this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
  var fb = new FormData();
  fb.append('file', img)
  this.service.showSpinner();
  this.service.postApiImg('account/upload-file', fb).subscribe(res => {
  this.service.hideSpinner();
  if (res['status'] == '200') {
  this.editImage = res['data'];  
  
  }
  }, err => {
  this.service.hideSpinner();
  if(err['status']=='401'){
    
    this.service.showErrorMessage('Unauthorized Access');
  }else{
 // this.service.toasterErr('Something Went Wrong');
}
  })
  }
}
