import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $ :any
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  pageNumber: number=1;
  userListDetails: any;
  userid: any;
  notificationList:any = [];
  item: any;
  universityId: any;
  accountDetails: any;
  constructor(private fb: FormBuilder, private router: Router,private spinner:NgxSpinnerService, private service: ServiceService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.accountDetails = JSON.parse(localStorage.getItem('ProfileDetails'))
  this.getNotification()
    
  }

  getNotification(){
    this.service.showSpinner()
    this.service.getApi(`course/get-notification-list?page=0&pageSize=100&universityId=`+this.accountDetails.universityDetailsId,1).subscribe((res:any) => {
      console.log(res)
      if(res.body.status == 200){
        this.notificationList = res.body.data.formListByCourse.content
      }
      this.service.hideSpinner()
      this.setSeenTrueNotification()
    })
  }

  setSeenTrueNotification(){
    this.service.postApi(`course/set-seen-true-notification-list?universityId=${this.accountDetails.universityDetailsId}`,{},1).subscribe((res:any) => {
      console.log("res seen-->>",res)
    })
  }
  deleteNotification(){
    this.service.showSpinner()
    this.service.getApi(`course/delete-notification-particular?notificationId=`+this.item + '&universityId='+this.accountDetails.universityDetailsId,1).subscribe((res:any) => {
     
      console.log("res-->>",res)
      if(res.body.status == 206){
        this.service.showSuccessMessage('Notification deleted successfully')
        this.getNotification()
      }
      this.service.hideSpinner()
    })
  }

  clearAll(){
    this.service.showSpinner()
    this.service.getApi(`course/delete-notification-list?universityId=`+this.accountDetails.universityDetailsId,1).subscribe((res:any) => {
      $("#deleteAllModal").modal('hide')
      console.log("res-->",res)
      if(res.body.status == 206){
        this.service.showSuccessMessage('All notifications deleted')
        this.getNotification()
      }
      this.service.hideSpinner()
    })
  }

  deleteParticularNotification(particularId){
    $("#deleteParticularModal").modal('show'); 
    this.item=particularId
    
  }

  deleteAllNotification(){
    $("#deleteAllModal").modal('show'); 
    
  }
}
